function reveal() {
  const reveals = document.querySelectorAll(".reveal");
  reveals.forEach((reveal, index) => {
    const windowHeight = window.innerHeight;
    const elementTop = reveal.getBoundingClientRect().top;
    const elementVisible = 50;
    const count =
      index > 30
        ? Math.round(index / 5)
        : index > 20
        ? Math.round(index / 4)
        : index > 10
        ? Math.round(index / 3)
        : index;
    const elementNumber = count > 6 ? Math.round(count / 2) : count;
    if (elementTop < windowHeight - elementVisible) {
      setTimeout(() => {
        reveal.classList.add("reveal--active");
      }, elementNumber * 100);
    }
  });
}

window.addEventListener("scroll", reveal);

reveal();
