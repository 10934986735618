const toggleBtn = document.querySelector(".menu-toggle");
const hamburger = document.querySelector(".hamburger");
const close = document.querySelector(".close");
const mobileMenu = document.querySelector(".mobile-menu");

if (toggleBtn) {
  toggleBtn.addEventListener("click", () => {
    hamburger.classList.toggle("icon-active");
    close.classList.toggle("icon-active");

    mobileMenu.classList.toggle("menu-open");
    if (mobileMenu.classList.contains("menu-open")) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "";
    }
  });
}

const header = document.querySelector(".header");

if (header) {
  let scrollPos = window.scrollY;
  const scrollChange = 1;
  window.addEventListener("scroll", () => {
    scrollPos = window.scrollY;
    if (scrollPos >= scrollChange) {
      header.classList.add("fixed-header");
    } else header.classList.remove("fixed-header");
  });
}
