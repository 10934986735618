document.onreadystatechange = () => {
  if (document.readyState === "complete") {
    const formControls = document.querySelectorAll(".wpcf7-form-control");
    const forms = document.querySelectorAll(".wpcf7-form");
    if (forms && formControls) {
      forms.forEach((el) => {
        el.removeAttribute("novalidate");
      });
      formControls.forEach((el) => {
        if (el.getAttribute("aria-required")) {
        }
        el.setAttribute("required", true);
      });
    }
  }
};
